<template>
  <div v-if="data">
    <v-divider></v-divider>
  </div>
</template>

<script>
import Base from './Base.vue'
export default {
  extends: Base,
}
</script>

<style lang="scss">
</style>